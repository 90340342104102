@font-face {
  font-family: 'Graphik Regular';
  src: url('./fonts/Graphik-Regular-Web.eot');
  src: url('./fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Graphik-Regular-Web.woff2') format('woff2'),
  url('./fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'Graphik Semibold';
  src: url('./fonts/Graphik-Semibold-Web.eot');
  src: url('./fonts/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Graphik-Semibold-Web.woff2') format('woff2'),
  url('./fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'Graphik Black';
  src: url('./fonts/Graphik-Black-Web.eot');
  src: url('./fonts/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Graphik-Black-Web.woff2') format('woff2'),
  url('./fonts/Graphik-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

html{
  margin:0;
  padding: 0;
}

body {
  font-family: 'Graphik Regular', Arial, sans-serif !important;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Graphik Black', 'Arial Black', sans-serif !important;
}

button, a, span {
  font-family: 'Graphik Regular', Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.electric-gradient {
  background: transparent linear-gradient(240deg, #c8079d 0%, #2839ab 100%) 0% 0% no-repeat padding-box !important;
}

.blue-gradient {
  background: transparent linear-gradient(180deg, #2839ab 0%, #00b0f0 100%) 0% 0% no-repeat padding-box !important;
}

.overlay {
  background: #0a0a0a 0% 0% no-repeat padding-box !important;
  opacity: 0.8;
}

.white-text{
  color: white;
}

.full-height{
  height: 100vh;
}

.transparent-bg{
  -moz-filter: blur(1px) brightness(100%) opacity(75%);
  background: rgba(255,255,255,0.3) 0% 0% no-repeat;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

@media (prefers-reduced-motion: no-preference) {

}
